<template>
  <div class="upload-image">
    <el-upload
      class="upload-oss"
      drag
      action
      :http-request="handleUpload"
      multiple
      :show-file-list="false"
      accept="image/webp, image/jpg, image/jpeg, image/png, image/gif"
    >
      <slot name="img">
        <div @drop="onDrop($event)">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ $t("productManagement.uploadTips") }}
            <em>{{ $t("productManagement.uploadClick") }}</em>
          </div>
        </div>
      </slot>
    </el-upload>
  </div>
</template>

<script>
import { uploadoss } from "@/helper/api.js";
import { getFileNameMd5, imageUrlToBase64ToFile } from "@/helper/ali-oss";
// import { createData,renderData } from "@/helper/api.js";
import { GetOssPolicyToken, AddOssFile } from "@/utils/api";
export default {
  data() {
    return {
      storeId: "",
      accessid: "",
      expire: "",
      host: "",
      policy: "",
      signature: "",
    };
  },
  created() {
    this.storeId = localStorage.getItem("storeId");
  },
  methods: {
    // oss签名
    getOssSignature() {
      return new Promise((resolve, reject) => {
        try {
          console.log("getOssSignature:");
          this.ossSignatureAvailable()
            .then((res) => {
              console.log("ossSignatureObj:", res);
              resolve(res);
            })
            .catch((err) => {
              console.log("ossSignatureObj-err:", err);
              // renderData("GET", "oss")
              //   .then((res) => {
              //     console.log("getOssSignature-res:", res);
              //     localStorage.setItem(
              //       "ossSignature",
              //       JSON.stringify(res.data)
              //     );
              //     resolve(res.data);
              //     // this.accessid = res.data.accessid;
              //     // this.expire = res.data.expire;
              //     // this.host = res.data.host;
              //     // this.policy = res.data.policy;
              //     // this.signature = res.data.signature;
              //   })
              //   .catch((error) => {
              //     reject(error);
              //   });
              GetOssPolicyToken()
                .then((res) => {
                  console.log("GetOssFileList", res);
                  localStorage.setItem(
                    "ossSignature",
                    JSON.stringify(res.data)
                  );
                  resolve(res.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    ossSignatureAvailable() {
      return new Promise(function (resolve, reject) {
        console.log("ossSignatureAvailable:");
        try {
          const ossSignatureJson = localStorage.getItem("ossSignature");
          console.log("ossSignatureJson:", ossSignatureJson);
          if (ossSignatureJson) {
            const ossSignatureObj = JSON.parse(ossSignatureJson);
            const nowUnix = Math.round(new Date().getTime() / 1000);
            if (
              ossSignatureObj &&
              ossSignatureObj.expire &&
              ossSignatureObj.expire > nowUnix &&
              ossSignatureObj.expire - nowUnix > 60 * 60 // 有效期大于1小时
            ) {
              resolve(ossSignatureObj);
            } else {
              reject(new Error("ossSignature expired"));
            }
          } else {
            reject(new Error("empty ossSignature"));
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    // 上传事件
    async handleUpload(option) {
      console.log("getAuth-option:", option);

      const file = option.file;
      const fileName = await getFileNameMd5(file);
      console.log("fileName:", fileName);

      const type = file.type;
      const size = file.size;
      const restype = type.substring(0, type.indexOf("/"));
      console.log("666666666", restype);

      // 限制图片的最大宽高
      const maxWidth = 500;
      const maxHeight = 550;

      let uploadFile = file; // 最终上传的文件

      if (restype === "image") {
        // 获取图片宽高并调整大小（如果需要）
        const isValidImage = await this.checkImageSize(
          file,
          maxWidth,
          maxHeight
        );
        if (isValidImage) {
          // 如果图片尺寸合适，直接上传
          uploadFile = file;
        } else {
          // 如果图片尺寸不合适，调整图片大小
          uploadFile = await this.resizeImage(file, maxWidth, maxHeight);
        }
      }

      const OssSignatureObj = await this.getOssSignature();
      // 上传到 OSS
      await uploadoss("POST", OssSignatureObj.host, {
        key: fileName,
        OSSAccessKeyId: OssSignatureObj.accessid,
        policy: OssSignatureObj.policy,
        signature: OssSignatureObj.signature,
        success_action_status: 200,
        file: uploadFile,
      }).then(() => {
        AddOssFile({
          categoryId: this.storeId || "",
          filename: fileName,
          filetype: restype,
          size: size,
        }).then((res) => {
          const url = `https://oss.sokogate.com/${fileName}`;
          console.log("handleUpload-uploadRes:", res, url);
          this.$emit("onsuccess", { filename: url });
        });
      });
    },

    // 检查图片尺寸并返回是否需要调整大小
    checkImageSize(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        // 读取文件内容并加载到图片中
        reader.onload = (e) => {
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            console.log(`Image width: ${width}, height: ${height}`);

            // 判断宽高是否符合要求
            if (width <= maxWidth && height <= maxHeight) {
              resolve(true); // 图片尺寸合格，无需调整
            } else {
              resolve(false); // 图片尺寸不合格，需要调整
            }
          };
          img.src = e.target.result;
        };

        reader.onerror = (err) => reject(err);

        reader.readAsDataURL(file); // 读取文件
      });
    },

    // 调整图片大小并满足额外条件：如果高度小于宽度，则宽度改为高度
    resizeImage(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
          img.onload = () => {
            let width = img.width;
            let height = img.height;

            // 如果图片的宽高超出了限制，按比例调整大小
            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width = width * ratio;
              height = height * ratio;
            }

            // 新增条件：如果高度小于宽度，则将宽度设置为和高度相同
            if (height < width) {
              width = height; // 将宽度调整为和高度相同
            }

            // 使用 canvas 调整图片大小
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // 将 canvas 转换为文件对象
            canvas.toBlob(
              (blob) => {
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                });
                resolve(resizedFile); // 返回调整后的文件
              },
              file.type,
              0.8 // 压缩质量 (0-1)
            );
          };

          img.src = e.target.result;
        };

        reader.onerror = (err) => reject(err);

        reader.readAsDataURL(file); // 读取文件
      });
    },

    onDrop(ev) {
      console.log("onDrop-ev:", ev);
      ev.preventDefault();
      const url = ev.dataTransfer.getData("URL");
      console.log("onChange-url:", url);
      if (url) {
        // 远程图片
        imageUrlToBase64ToFile(url)
          .then((file) => {
            this.handleUpload({ file });
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              message: this.$t("common.invalidPictureAddress"),
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_responsive.scss";

.upload-image {
  width: 100%;
  height: 100%;
  .upload-oss {
    width: 100%;
    height: 100%;
    .el-upload.el-upload--text {
      width: 100%;
      height: 100%;
      .el-upload-dragger {
        width: 100%;
        height: 100%;

        .el-icon-upload {
          margin-top: 15px;
        }
      }
    }
  }
}
// .upload-oss {
//   width: 360px;
//   height: 180px;

//   @include mobile {
//     width: 100px;
//     height: 100px;
//   }

//   .dorp {
//     @include mobile {
//       display: none;
//     }
//   }
// }
</style>
